import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { DemoMaterialModule } from './material.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { LoginComponent } from './Login/Login.component';
import { UserDetailsService } from './Services/UserDetails.service';

import { LoginService } from './Services/Login.service';
import { UserService } from './Services/user.service';
import { LeaveMgmtService } from './Services/LeaveMgmt.service';
import { ErrorMgmtService } from './Services/ErrorMgmt.service';
import { TrainingMgmtService } from './Services/TrainingMgmt.service';
import { ReportService } from './Services/Report.service';
import { CapacityPlanningService } from './Services/CapacityPlanning.service';
import { SharepointService } from './Services/Sharepoint.service';
import { CoverSheetService } from './Services/CoverSheet.service';
import { MBRService } from './Services/MBR.service';
import { ErrorReportService } from './Services/ErrorReport.service';
import { CerosService } from './Services/Ceros.service';
import { RPADemoService} from './Services/RPADemo.service';
import { TrueLinkService } from './Services/TrueLink.service';
import { FortisService } from './Services/Fortis.service';

const routes: Routes = [
    { path: '', component: LoginComponent, pathMatch: 'full' },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
            }]
    },
    { path: 'Login', component: LoginComponent },
    { path: '**', redirectTo: 'Login' },
];

@NgModule({
    
    imports: [
        DemoMaterialModule,
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
    providers: [
        LoginService,
        UserService,
        LeaveMgmtService,
        ErrorMgmtService,
        TrainingMgmtService,
        ReportService,
        CapacityPlanningService,
        SharepointService, CoverSheetService, MBRService, ErrorReportService, CerosService,
        RPADemoService,
        UserDetailsService,
        TrueLinkService,
        FortisService,
    ]
})
export class AppRoutingModule { }
