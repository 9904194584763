﻿import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';

@Injectable()
export class TrueLinkService {
    API_CREATE_REPORT = "/exportFileService"
    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

    CreateReport(model) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            startDate: model.startDate,
            endDate: model.endDate,
            accountID: model.accountID
        })
        let url = environment.API_URL + this.API_CREATE_REPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}