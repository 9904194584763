import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable()
export class LeaveMgmtService {
    myAppUrl: string = "";
    API_ENDPOINT_LEAVETYPE = '/getLeaveType';
    API_ENDPOINT_LEAVEDATA = '/getUserLeave';
    API_ENDPOINT_DELETE = '/deleteLeave';
    API_ENDPOINT_UPDATELEAVE = '/updateLeaveMgmt';
    API_ENDPOINT_INSERTLEAVE= '/addLeave';
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
    this.myAppUrl = baseUrl;
  }

  InsertLeaveData(model) {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let data = JSON.stringify({
          userId : model.userId,
          userName : model.userName,
          plAndUnpl : model.PL,
          from: model.FromDate,
          to : model.ToDate
      })
      let url = environment.API_URL + this.API_ENDPOINT_INSERTLEAVE;
      let httpOptions = {
          headers: new HttpHeaders({
              'Authorization': token_type + " " + access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'

          })
      }
      return this.Http.post(url, data, httpOptions);
  }

  SearchLeaveData(userName: string, FromDate: string, ToDate:string) {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let data = JSON.stringify({
          userName: userName,
          from: FromDate,
          to: ToDate
      })
      let url = environment.API_URL + this.API_ENDPOINT_LEAVEDATA;
      let httpOptions = {
          headers: new HttpHeaders({
              'Authorization': token_type + " " + access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'

          })
      }
      return this.Http.post(url, data, httpOptions);
  }

  GetLeaveTypes() {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let url = environment.API_URL + this.API_ENDPOINT_LEAVETYPE;
      let httpOptions = {
          headers: new HttpHeaders({
              'Authorization': token_type + " " + access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'

          })
      }
      return this.Http.get(url, httpOptions);

  }

  DeleteLeaveData(autoId: string) {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let data = JSON.stringify({
          autoId: autoId,

      })
      let url = environment.API_URL + this.API_ENDPOINT_DELETE;
      let httpOptions = {
          headers: new HttpHeaders({
              'Authorization': token_type + " " + access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'

          })
      }
      return this.Http.post(url, data, httpOptions);

  }

  UpdateLeaveData(model) {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let data = JSON.stringify({
          autoId: model.autoId,
          plAndUnpl: model.PL,
          from: model.FromDate,
          to: model.ToDate
      })
      let url = environment.API_URL + this.API_ENDPOINT_UPDATELEAVE;
      let httpOptions = {
          headers: new HttpHeaders({
              'Authorization': token_type + " " + access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          })
      }
      return this.Http.put(url, data, httpOptions);
  }
  
  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
