export class UserAPIResponse {
    response: APIResponse;
    status: string;
}

export class APIResponse {
    User: User[];
}

export class User {
    userId: string;
    userName: string;
    roleId: string;
    role: string;
    departmentId: string;
    departmentName: string;
    dateOfJoining: any;
    dateOfLeaving: any;
    responseMsg: string;
    isActive: string;

}

export class UserDetails {
    username: string;
    displayname: string;
    responsemsg: string;
}

//update user
export class UserUpdate{
    status: string;
    response: UpdateActiveUser;
}
export class UpdateActiveUser {
    message: string;
}


export class UserRole {
    roleid: string;
    role: string;
    responsemsg: string;
}
export class Department {
    response: GetDepartment;
    status: string;
}
export class GetDepartment {
    AllDepartment: AllDepartment[];
}
export class AllDepartment {
    departmentid: string;
    departmentname: string;
    responsemsg: string;
}


export class ADUserResponse {
    response: ADUserData;
    status: string;
}

export class ADUserData {
    ADUser: ADUserDetails[];
}

export class ADUserDetails {
    displayName: string;
    email: string;
    userName: string;
}

