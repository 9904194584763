﻿import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class RPADemoService {
    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string) {
        this.myAppUrl = baseUrl;
    }

    GetFiles() {
        debugger;
        return this._http.get(this.myAppUrl + 'api/Demo/FileList')
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    DeleteFile(Key: string) {
        return this._http.get(this.myAppUrl + 'api/Demo/Delete/' + Key)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    public getFile(path: string): Observable<any> {
        debugger;
        let options = new RequestOptions({ responseType: ResponseContentType.Blob });
        return this._http.get(this.myAppUrl + 'api/Download/' + path, options)
            .map((response: Response) => <Blob>response.blob());
    }

    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
