// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    //API_URL: "http://localhost:61065/HALService.svc",
    API_URL: "http://hal.empaxis.com:8090/v1/HALService.svc",
    //API_URL: "https://hal.empaxis.com:8443/v1/HALService.svc",
    production: false
};
