
import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';
import { Injectable, Inject } from '@angular/core';

@Injectable()

export class UserService {
    API_ENDPOINT_AD_USERDETAILS = '/getADUserDetails';
    API_ENDPOINT_USERDETAILS = '/getUserDetails';
    API_ENDPOINT_UPDATEACTIVEUSER = '/userActivation';
    API_ENDPOINT_DELETE = '/deleteUser';
    API_ENDPOINT_DEPARTMENT = '/getDepartmentList';
    API_ENDPOINT_DETAILS = '/getUserDetails';
    API_ENDPOINT_ADDUSER = '/addUser';

    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }



    RemoveDigit(Username: string) {
        return this._http.get(this.myAppUrl + 'api/RemoveDigit/Remove/' + Username)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

//-----------------------------------------------------------------------------------------//

    //GetUserList(model) {
    //    return this._http.post(this.myAppUrl + 'api/ActiveUser/Authentication' , model )
    //  .map((response: Response) => response.json())
    //  .catch(this.errorHandler);
    //}


    GetUserList(model) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            sessionId: model.SessionName,
            password: model.Password,
            searchKey: model.userId
        })
        let url = environment.API_URL + this.API_ENDPOINT_AD_USERDETAILS;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
    }


    GetUserList1(model) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: model.userId,
            flag: "1"
        })
        let url = environment.API_URL + this.API_ENDPOINT_USERDETAILS;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
    }




//-----------------------------------------------------------------------------------------//
    //UpdateActiveUser(UserId: string, SessionID: string, IsActive: string) {
    //    return this._http.get(this.myAppUrl + 'api/UpdateuserActivation/Authorization' + UserId + '/' + SessionID + '/' + IsActive)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    UpdateuserActivation(userId, isActive) {

        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            userId: userId,
            isActive: isActive
        })
        let url = environment.API_URL + this.API_ENDPOINT_UPDATEACTIVEUSER;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
   
    }

    //Delete(userId: string) {
    //    return this._http.get(this.myAppUrl + 'api/DeleteUser/Authorization' + userId)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    DeleteUser(userId) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            userId: userId,
        })
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.delete(data, httpOptions);
    }


 //----------------------------------------------------------------------------------------//

  //InsertUserData(model) {
  //  return this._http.post(this.myAppUrl + 'api/User/Insert', model)
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }


    InsertUserData(model) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            userId: model.userId,
            userName: model.UserName,
            roleId: model.Role,
            departmentId: model.Department,
            dateOfJoining: model.DateOfJoining,
            sessionId: model.SessionName,
            password: model.Password,
            dateOfLeaving: model.DateOfLeaving
        })
        let url = environment.API_URL + this.API_ENDPOINT_ADDUSER;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
    }


 //----------------------------------------------------------------------------------------//

    SearchUserData(searchKey, flag) {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: searchKey,
            flag: flag
        })
        let url = environment.API_URL + this.API_ENDPOINT_DETAILS;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
    }

//---------------------------------------------------------------------------------------//
    GetUserRole() {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: "",
            flag: "1"
        })
        let url = environment.API_URL + this.API_ENDPOINT_DETAILS;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
  }

 


  UpdateUser(model) {
    return this._http.post(this.myAppUrl + 'api/User/Update', model)
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  //GetDepartmentDetails() {
  //    return this._http.get(this.myAppUrl + 'api/GetDepartment/Authorization')
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //}
    GetDepartments() {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let url = environment.API_URL + this.API_ENDPOINT_DEPARTMENT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.get(url, httpOptions);
    }
  

  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
