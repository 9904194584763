﻿
import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';
import { Injectable, Inject } from '@angular/core';


@Injectable()
export class UserDetailsService {
    API_ENDPOINT_DETAILS = '/getUserDetails';
    API_ENDPOINT_UPDATEUSER = '/updateUser';
    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

    userDetails(model) {
        return this._http.post(environment.API_URL + 'api/User/Authentication', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    User(searchKey, flag) {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: searchKey,
            flag: flag            
        })
        let url = environment.API_URL + this.API_ENDPOINT_DETAILS;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
    }

    UpdateUserDetails(model) {
        return this._http.post(this.myAppUrl + 'api/UpdateUser/Authorization', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    UpdateUser(model) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            userId: model.userId,
            UserName: model.UserName,
            roleId: model.Role,
            departmentId: model.Department,
            dateOfJoining: model.JoiningDate,
            dateOfLeaving: model.LeavingDate
        })
        let url = environment.API_URL + this.API_ENDPOINT_UPDATEUSER;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        }
        return this.Http.put(url, data, httpOptions);
    }


    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}