import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

export function getBaseUrl() {
    //return document.getElementsByTagName('base')[0].href;
    //return "https://hal.empaxis.com/";
    //return "http://hal.empaxis.com/";
    //return "http://35.154.112.0:80/";
    // return "https://hal.empaxis.com:8443//HALService.svc";
    return "";

}

const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
    enableProdMode();
}

//platformBrowserDynamic().bootstrapModule(AppModule);

platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.log(err));