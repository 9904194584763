import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class ErrorMgmtService {
    myAppUrl: string = "";
API_ENDPOINT_GETError = '/getError';


    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
    this.myAppUrl = baseUrl;
  }

  InsertErrorData(model) {
    return this._http.post(this.myAppUrl + 'api/ErrorMgmt/Insert', model)
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

    SearchErrorData(ClientName: string) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: ClientName
        })

        let url = environment.API_URL + this.API_ENDPOINT_GETError;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }


  GetClientList() {
    return this._http.get(this.myAppUrl + 'api/ErrorMgmt/ClientList')
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  DeleteError(AutoID: string) {
    return this._http.get(this.myAppUrl + 'api/ErrorMgmt/Delete/' + AutoID)
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  UpdateError(model) {
    return this._http.post(this.myAppUrl + 'api/ErrorMgmt/Update', model)
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
