﻿import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class ErrorReportService {
    API_CLIENT_WISE_ERROR_REPORT = '/MBRClientWiseError';
    API_ERROR_REPORT = '/ErrorAnalysisReportDetails';
    API_ANALYST_WISE_ERROR_REPORT = '/MBRAnalystWiseError';
    API_ENDPOINT_ERROR_TYPE = '/GetErrorReport';
    API_ERROR_REPORT_BYFIRM = '/ErrorByFirm';
    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }


    //*******ERROR REPORT*********//

    GetFirmName() {
        return this._http.get(this.myAppUrl + 'api/ErrorReport/Firmname')
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    GetErrorTypes() {
        return this._http.get(this.myAppUrl + 'api/ErrorReport/Type')
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

//----------------------------------------------------------------------------------------------------//
    //GetErrorReport_Part1(model) {
    //    return this._http.post(this.myAppUrl + 'api/ErrorReport/FirstTab', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetErrorReport_Part1(start_Date, end_Date, firm_Name, analyst_Name: string = " ",error_Type: string = " ") {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            startDate: start_Date,
            endDate: end_Date,
            firmName: firm_Name.toString(),
            analystName: analyst_Name.toString(),
            errorType: error_Type.toString()
        })
        let url = environment.API_URL + this.API_ERROR_REPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

//----------------------------------------------------------------------------------------------------//

    //GetErrorReport_Part2(model) {
    //    return this._http.post(this.myAppUrl + 'api/ErrorReport/SecondTab', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetErrorReport_Part2(report_Month) {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            reportMonth: report_Month
        })
        let url = environment.API_URL + this.API_ANALYST_WISE_ERROR_REPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }
//----------------------------------------------------------------------------------------------------//

    //GetErrorReport_Part3(model) {
    //    return this._http.post(this.myAppUrl + 'api/ErrorReport/ThirdTab', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetErrorReport_Part3(report_Month) {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            reportMonth: report_Month
        })
        let url = environment.API_URL + this.API_CLIENT_WISE_ERROR_REPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }
//----------------------------------------------------------------------------------------------------//

    //GetErrorReport_Part4_FirmList(model) {
    //    return this._http.post(this.myAppUrl + 'api/ErrorReport/FourthTab1', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}


    /*
     * flag: 1 = Error Report by Firmname
     * flag: 2 = Error Report by Analyst Name 
     * flag: 3 = Error Report by Error Category
     */

    GetErrorReport_Part4(flag) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({

        })
        let url = environment.API_URL + this.API_ENDPOINT_ERROR_TYPE + "/" + flag;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.get(url, httpOptions);
    }




    GetErrorReport_Part4_ByFirm(start_Date, end_Date) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            startDate: start_Date,
            endDate: end_Date
        })
        let url = environment.API_URL + this.API_ERROR_REPORT_BYFIRM;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

//----------------------------------------------------------------------------------------------------//


    GetErrorReport_Part4_B(Key: string) {
        return this._http.get(this.myAppUrl + 'api/ErrorReport/FourthTab2/' + Key)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
