import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';


@Injectable()
export class SharepointService {
    API_ENDPOINT_SHAREPOINT = '/sharepointServices';

    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

  //GetSharepointData() {
  //  debugger;
  //    return this._http.get(this.myAppUrl + "api/Sharepoint/Authentication" )
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }

    Sharepoint(RelativeLink) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            folderName: RelativeLink
        })
        let url = environment.API_URL + this.API_ENDPOINT_SHAREPOINT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
    }
    

  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
