﻿import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';

@Injectable()
export class MBRService {
    API_MBR_CLIENT_SATISFACTION = '/MBRClientSatisfaction';
    API_MBR_TEAM_TENURE = '/MBRReconTenure';
    API_MBR_MANIC_TIME = '/MBRManicTimeReport';
    API_MBR_FTE_RATIO = '/MBRClientFTERatio';
    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

    //********MBR*******//

    GetMBRReport(model) {
        return this._http.post(this.myAppUrl + 'api/MBR/GetReport', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    GetYear() {
        return this._http.get(this.myAppUrl + 'api/MBR/Year')
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }


//------------------------------------------------------------------------------------------//

    //GetMBR_Part1(Month: string, Year: string, Flag: string) {
    //    return this._http.get(this.myAppUrl + 'api/MBR/FirstTab/' + Month + '/' + Year + '/' + Flag)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetMBR_Part1(Month: string, Year: string, Flag: string) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            month: Month,
            year: Year
        })
        let url = environment.API_URL + this.API_MBR_CLIENT_SATISFACTION;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

 //------------------------------------------------------------------------------------------//

    //GetMBR_Part2(model) {
    //    return this._http.post(this.myAppUrl + 'api/MBR/SecondTab', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetMBR_Part2(Month: string, Year: string) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            reportMonth: Month + " " + Year
        })
        let url = environment.API_URL + this.API_MBR_FTE_RATIO;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }


//------------------------------------------------------------------------------------------//

    //GetMBR_Part3(model) {
    //    return this._http.post(this.myAppUrl + 'api/MBR/ThirdTab', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetMBR_Part3(Month: string, Year: string) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            reportMonth: Month + " " + Year
        })
        let url = environment.API_URL + this.API_MBR_MANIC_TIME;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

//------------------------------------------------------------------------------------------//

    //GetMBR_Part4(model) {
    //    return this._http.post(this.myAppUrl + 'api/MBR/FourthTab', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetMBR_Part4(Month: string, Year: string) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            reportMonth: Month + " " + Year
        })
        let url = environment.API_URL + this.API_MBR_TEAM_TENURE;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
