import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable()
export class CapacityPlanningService {
    myAppUrl: string = "";
    API_ENDPOINT_TASKTYPE = '/getTaskType';
    API_ENDPOINT_INSERTCAPADATA = '/addCapacityPlanning';
    API_ENDPOINT_SEARCHCAPADATA = '/getCapacityPlanning';
    API_ENDPOINT_DELETECAPA = '/deleteCapacityPlanning';
    API_ENDPOINT_UPDATECAPA = '/updateCapacityPlanning';
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
    this.myAppUrl = baseUrl;
  }

  SearchCAPAData(responsiblePerson: string) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            responsiblePerson: responsiblePerson
        })

        let url = environment.API_URL + this.API_ENDPOINT_SEARCHCAPADATA;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

  InsertCAPAdata(model) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            clientName: model.ClientName,
            taskType: model.TaskType,
            responsiblePerson: model.ResponsiblePerson,
            date: model.Date,
            targetHour: model.TargetHours,
            shiftTiming: model.ShiftTiming,
            sessionId: model.UserID,
            clientSla: model.ClientSLA,
            taskTitle: model.TaskTitle,
            tentativeBackup: model.BackupResource
        })

        let url = environment.API_URL + this.API_ENDPOINT_INSERTCAPADATA;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

  GetTaskType() {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let url = environment.API_URL + this.API_ENDPOINT_TASKTYPE;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.get(url, httpOptions);
    }

  DeleteCAPAdata(serialNo: string) {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let data = JSON.stringify({
          serialNo: serialNo
      })

      let url = environment.API_URL + this.API_ENDPOINT_DELETECAPA;
      let httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': token_type + " " + access_token
          })
      }
      return this.Http.delete(url, httpOptions);
  }
    
  UpdateCAPAData(model) {
      debugger;
      let access_token = localStorage.getItem('access_token');
      let token_type = localStorage.getItem('token_type');
      let data = JSON.stringify({
          clientName: model.cilentname,
          taskType: model.Tasktype,
          responsiblePerson: model.RP,
          date: model.date,
          targetHour: model.Thours,
          shiftTiming: model.shiftT,
          sessionId: model.sessionId,
          clientSla: model.cilentsla,
          taskTitle: model.TT,
          tentativeBackup: model.TB,
          serialNo: model.SrNo
      })


      let url = environment.API_URL + this.API_ENDPOINT_UPDATECAPA;
      let httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': token_type + " " + access_token
          })
      }
      return this.Http.put(url, data, httpOptions);
  }

  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
