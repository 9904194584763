import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from 'environments/environment';


@Injectable()
export class LoginService {
    API_ENDPOINT_LOGIN = '/logIn';

    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

    loginDetails(model) {
        return this._http.post(environment.API_URL + 'api/Login/Authentication', model)
                     .map((response: Response) => response.json())
                     .catch(this.errorHandler);
    }

    Login(model) {
        //debugger;
        let data = JSON.stringify({
            userName: model.UserName,
            password: model.Password
        })
        let url = environment.API_URL + this.API_ENDPOINT_LOGIN;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        }
        return this.Http.post(url, data, httpOptions);
    }


    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
