import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { DemoMaterialModule } from './material.module';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng4-charts/ng4-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { environment } from '../environments/environment';


import { AppComponent } from './app.component';

import { LoginComponent } from './Login/Login.component';


import {
    AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule, ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        ComponentsModule,
        ChartsModule,
        RouterModule,
        AppRoutingModule,
        DemoMaterialModule,
        FileUploadModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        })
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
      ],
    providers: [
        CookieService,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
