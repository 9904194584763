import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable()
export class TrainingMgmtService {
    myAppUrl: string = "";
    API_ENDPOINT_UPDATE = '/updateTraining';
    API_ENDPOINT_SEARCHDATA = '/getTrainingList';
    API_ENDPOINT_UPDATEDATA = '/updateTrainingAttended';
    API_ENDPOINT_INSERT= '/addTrainingPlanning';
    API_ENDPOINT_TRAININGTYPE= '/trainingType';

    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
      this.myAppUrl = baseUrl;
      
  }

  //InsertTrainingDetail(model) {
  //    return this._http.post(this.myAppUrl + 'api/InsertTraining/Authorization', model)
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }
  InsertTraining(model) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            userId: model.userId,
            username: model.userName,
            trainingModule: model.trainingType,
            Task: model.Task,
            Date: model.Date,
            trainerId: model.trainerId
        })

        let url = environment.API_URL + this.API_ENDPOINT_INSERT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);

    }

  //GetTrainingType() {
  //  return this._http.get(this.myAppUrl + 'api/TrainingMgmt/GetTrainingType')
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }

    GetTrainingType() {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let url = environment.API_URL + this.API_ENDPOINT_TRAININGTYPE;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        }
        return this.Http.get(url, httpOptions);
    }

  UpdateAttendData(model) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            trainingList: model
        })

      let url = environment.API_URL + this.API_ENDPOINT_UPDATEDATA;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);


    }


  SearchData(userName, startDate, endDate) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            userName: userName,
            startDate: startDate,
            endDate: endDate
        })
        let url = environment.API_URL + this.API_ENDPOINT_SEARCHDATA;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url, data, httpOptions);
  
    }

  //DeleteDataDetail(autoID: string) {
    //  return this._http.delete(this.myAppUrl + "api/DeleteData/Authorization" , autoID)
    //  .map((response: Response) => response.json())
    //  .catch(this.errorHandler);
    //}

   DeleteData(autoId) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            autoId: autoId
        })
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.delete(data, httpOptions);
    }

  //UpdateDataDetails(model) {
  //  debugger;
  //    return this._http.post(this.myAppUrl + "api/UpdateData/Authorization", model)
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }

   UpdateData(model) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            autoId: model.autoId,
            trainingModule: model.trainingType,
            Task: model.Task,
            Date: model.Date,
            trainerId: model.trainerId
        })
        let url = environment.API_URL + this.API_ENDPOINT_UPDATE;
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.post(url,data, httpOptions);
    }

  //GetSharepointData() {
  //  return this._http.get(this.myAppUrl + "api/Sharepoint/Get")
  //    .map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //}

  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
