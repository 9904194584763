
import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';
import { Injectable, Inject } from '@angular/core';


@Injectable()
export class ReportService {
    API_ENDPOINT_REPORT = '/getFirmMaster';
    API_ENDPOINT_RUNKPI = '/runKPI';
    API_ENDPOINT_KPIREPORT = '/KPIReport';
    API_ENDPOINT_TICKETMONITORREPORT = '/GetTicketMonitorReport';
    API_ENDPOINT_ERROR_TYPE = '/GetErrorReport';
    myAppUrl: string = "";
    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

    //GetFirmName(model) {
    //    debugger;
    //    return this._http.post(environment.API_URL + 'api/Client/Authentication', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetFirmName(model) {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: ""
        })
        let url = environment.API_URL + this.API_ENDPOINT_REPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

    Client() {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            searchKey: ""
        })
        let url = environment.API_URL + this.API_ENDPOINT_REPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }


    GetMBRReport(model) {
        return this._http.post(this.myAppUrl + 'api/MBR/GetReport', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    GetYear() {
        return this._http.get(this.myAppUrl + 'api/MBR/Year')
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    //*******ERROR REPORT*********//

    GenerateErrorReport(model) {
        debugger;
        return this._http.post(this.myAppUrl + 'api/ErrorReport/Generate', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

//-------------------------------------------------------------------------------------//
   
    //GetErrorTypes() {
    //    return this._http.get(this.myAppUrl + 'api/ErrorReport/Type')
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetErrorTypes() {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
           
        })
        let url = environment.API_URL + this.API_ENDPOINT_ERROR_TYPE+"/3";
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.get(url, httpOptions);
    }


    


    //**************TICKET MONITOR REPORT**************//
    GenerateTicketMonitorReport(model) {
        console.log("Inside Service");
        return this._http.post(this.myAppUrl + 'api/TicketMonitor/Generate', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    //GetTicketMonitorReportData(model) {
    //    return this._http.post(this.myAppUrl + 'api/TicketMonitor/GetReportData', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}


    GetTicketMonitorReportData(fromDate, toDate, firm_Name) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            startDate: fromDate,
            endDate: toDate,
            firmName: firm_Name
        })
        let url = environment.API_URL + this.API_ENDPOINT_TICKETMONITORREPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }

    //****************KPI REPORT***************//
    //GetKPI(model) {
    //    return this._http.post(this.myAppUrl + 'api/KPI/Get', model)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    GetKPI(fromDate, toDate) {
        //debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            startDate: fromDate,
            endDate: toDate
        })
        let url = environment.API_URL + this.API_ENDPOINT_KPIREPORT;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }


    //RunKPI(date: string) {
    //    return this._http.get(this.myAppUrl + 'api/KPI/Run/' + date)
    //        .map((response: Response) => response.json())
    //        .catch(this.errorHandler);
    //}

    RunKPI(date) {
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            date: date
        })
        let url = environment.API_URL + this.API_ENDPOINT_RUNKPI;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token_type + " " + access_token
            })
        }
        return this.Http.post(url, data, httpOptions);
    }
    

    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
  



//import { Injectable, Inject } from '@angular/core';
//import { Http, Response, ResponseContentType } from '@angular/http';
//import { Observable } from 'rxjs/Observable';
//import { Router } from '@angular/router';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/observable/throw';

//@Injectable()
//export class ReportService {
//    myAppUrl: string = "";
//    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string) {
//        this.myAppUrl = baseUrl;
//    }

//    //********MBR*******//

//    GetMBRReport(model) {
//        return this._http.post(this.myAppUrl + 'api/MBR/GetReport', model)
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    GetYear() {
//        return this._http.get(this.myAppUrl + 'api/MBR/Year')
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    //*******ERROR REPORT*********//

//    GenerateErrorReport(model) {
//        debugger;
//        return this._http.post(this.myAppUrl + 'api/ErrorReport/Generate', model)
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    GetFirmName() {
//        return this._http.get(this.myAppUrl + 'api/ErrorReport/Firmname')
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    GetErrorTypes() {
//        return this._http.get(this.myAppUrl + 'api/ErrorReport/Type')
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }


//    //**************TICKET MONITOR REPORT**************//
//    GenerateTicketMonitorReport(model) {
//        console.log("Inside Service");
//        return this._http.post(this.myAppUrl + 'api/TicketMonitor/Generate', model)
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    GetTicketMonitorReportData(model) {
//        return this._http.post(this.myAppUrl + 'api/TicketMonitor/GetReportData', model)
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    //****************KPI REPORT***************//
//    GetKPI(model) {
//        return this._http.post(this.myAppUrl + 'api/KPI/Get', model)
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    RunKPI(date: string) {
//        return this._http.get(this.myAppUrl + 'api/KPI/Run/' + date)
//            .map((response: Response) => response.json())
//            .catch(this.errorHandler);
//    }

//    errorHandler(error: Response) {
//        console.log(error);
//        return Observable.throw(error);
//    }
//}
