import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../Services/Login.service';
import { UserService } from '../Services/user.service';
import { UserAPIResponse, User } from '../Models/User.model';
import { Login } from '../Models/Demo';
import { CookieService } from 'ngx-cookie-service';
import { plainToClass } from "class-transformer";
import Alert from 'sweetalert2';
import { LoginResponse } from '../Models/Login.model';
import { UserDetailsService } from 'app/Services/UserDetails.service';


@Component({
    selector: 'app-Login',
    templateUrl: './Login.component.html',
    styleUrls: ['./Login.component.css', '../../assets/css/Loader.css']
})
export class LoginComponent implements OnInit {

    model: any = {};
    result: string;
    error: string;
    Role: string;

    isChecked: boolean = false;
    isValid: boolean = false;
    isEnable: boolean = true;
    isButton: boolean = true;

    loginResponse: LoginResponse;
    userDetail: UserAPIResponse;

    //LoginDetails: Login;

    errorReponse: any;

    constructor(private _http: HttpClient, private _avRoute: ActivatedRoute, private _router: Router, private http: Http,
        private _loginService: LoginService, private _userDetails: UserDetailsService,
        private _cookieService: CookieService) {
    }

    ngOnInit() {
        if ((localStorage.getItem('RememberMe')) == "true") {
            this.model.UserName = localStorage.getItem('Username');
            this.model.Password = localStorage.getItem('Password');
            this.isChecked = true;
        }
    }

    login() {
        if (this.model.UserName != null && this.model.Password != null &&
            this.model.UserName != "" && this.model.Password != "") {
            this.error = null;
            this.isValid = true;
            this.isButton = false;
            //debugger;
            this._loginService.Login(this.model).subscribe(resp => {
               
                this.loginResponse = plainToClass(LoginResponse, resp);
                if (this.loginResponse.status.toUpperCase() == "SUCCESS") {
                    localStorage.setItem('access_token', this.loginResponse.response.access_token);
                    localStorage.setItem('token_type', this.loginResponse.response.token_type);
                    this.setRememberMe();
                    this._userDetails.User(this.model.UserName, 1).subscribe(resp1 => {
                        //debugger;
                        this.userDetail = plainToClass(UserAPIResponse, resp1);
                        if (this.userDetail.status.toUpperCase() == "SUCCESS") {
                            localStorage.setItem("user", this.model.UserName);
                            localStorage.setItem("XYZ", this.model.Password);
                            localStorage.setItem("Session", "true");
                            localStorage.setItem("Role", this.userDetail.response.User[0].role);
                            this._router.navigate(['/dashboard']);
                        }
                    });
                }
            }, error => {
                this.errorReponse = error.error;
                this.isValid = false;
                this.isButton = true;
                switch (this.errorReponse.ErrorCode) {
                    case '1015': {
                        this.error = "Inactive User! Please contact admin to activate.";
                        break;
                    }
                    case '1003': {
                        this.error = "UserName or Password is incorrect";
                        break;
                    }
                    default:
                        break;
                }
            });
        }

    }

    setRememberMe() {
        if (this.isChecked) {
            //alert("Inside IF");
            localStorage.setItem('Username', this.model.UserName);
            localStorage.setItem('Password', this.model.Password);
            localStorage.setItem('RememberMe', String(this.isChecked));
        }
        else {
            //alert("Inside Else");
            localStorage.removeItem('Username');
            localStorage.removeItem('RememberMe');
        }
    }
}

//model(var)