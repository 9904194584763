import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../Services/user.service';
import { LoginService } from 'app/Services/Login.service';
declare var jQuery: any;

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [

];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {
    menuItems: any[];

    isClient: boolean = false;
    isReport: boolean = false;
    isLeave: boolean = false;
    isError: boolean = false;
    isTraining: boolean = false;
    isHoliday: boolean = false;
    isUserMgmt: boolean = false;
    isCAPA: boolean = false;
    isDemo: boolean = false;
    isSharepoint: boolean = false;
    isICData: boolean = false;

    constructor(private cookieService: CookieService, private _loginService: LoginService) {
        if (localStorage.getItem("Session") == "true") {
            //debugger;
            this.UserRole(localStorage.getItem('Role'));
        }
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    ClearSession() {
        localStorage.deleteAll();
    }

    ngAfterViewInit() {
        jQuery('#side-menu').metisMenu();
    }

    UserRole(Value: string) {
        //debugger;
        switch (Value.toUpperCase()) {
            case "ADMIN":
                {
                    this.isClient = true;
                    this.isReport = true;
                    this.isLeave = true;
                    this.isError = true;
                    this.isTraining = true;
                    this.isHoliday = true;
                    this.isUserMgmt = true;
                    this.isCAPA = true;
                    this.isSharepoint = true;
                    this.isICData = true;
                    this.ngAfterViewInit();
                    break;
                }

            case "MANAGEMENT":
                {
                    this.isClient = true;
                    this.isReport = true;
                    this.isSharepoint = true;
                    this.ngAfterViewInit();
                    break;
                }
            case "BUSINESS ANALYST":
                {
                    this.isClient = true;
                    this.ngAfterViewInit();
                    break;
                }
            case "TEAM LEAD":
                {
                    this.isClient = true;
                    this.isReport = true;
                    this.isLeave = true;
                    this.isError = true;
                    this.isTraining = true;
                    this.isHoliday = true;
                    this.ngAfterViewInit();
                    break;
                }
            case "DEMO":
                {
                    this.ngAfterViewInit();
                    break;
                }
            default:
                //alert("ISSUE");
                break;
        }
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };


}
