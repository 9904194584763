import { Injectable, Inject } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { errorHandler } from '@angular/platform-browser/src/browser';
import { error } from 'console';

@Injectable()
export class CoverSheetService {
    myAppUrl: string = "";

    constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string, private Http: HttpClient) {
        this.myAppUrl = baseUrl;
    }

    GetDataBank(key: string) {
        return this._http.get(this.myAppUrl + 'api/GetData/Authentication' , key)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }
    GetData(key) {
        debugger;
        let access_token = localStorage.getItem('access_token');
        let token_type = localStorage.getItem('token_type');
        let data = JSON.stringify({
            key: key
        })
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': token_type + " " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            })
        }
        return this.Http.get(data, httpOptions);
    }
    




    Operation(model) {
        return this._http.post(this.myAppUrl + 'api/CoverSheet/Operation', model)
            .map((response: Response) => response.json())
            .catch(this.errorHandler);
    }

    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
